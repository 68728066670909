import React from "react"

import BottomWave from "/src/assets/BottomWave.svg"
import WorkspaceImage from "/src/assets/undraw_Code_thinking_re_gka2.svg"

export default function ProductHero(props) {
    return (
        <>
            <div className="pt-24">
                <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center lg:mb-0">
                    <div className="md:ml-14 mb-10 md:mb-0 md:pt-16 md:max-w-2xl md:w-full flex flex-col items-start">
                        <h1 className="text-4xl font-extrabold leading-10 tracking-tight sm:text-5xl sm:leading-none md:text-6xl">
                            Subscriptions as a Service
                        </h1>

                        <h2 className="my-5 text-base sm:text-lg sm:max-w-xl md:text-xl md:mx-0">
                            LinkFive simplifies your subscription business from creating your subscriptions in all App
                            Stores to implementing purchases in your app.
                        </h2>
                    </div>

                    <div className="w-full md:w-2/5 py-6 lg:ml-auto mx-auto xl:block hidden">
                        <WorkspaceImage className="w-full md:w-4/5 z-50 lg:mx-auto"/>
                    </div>
                </div>
            </div>
            <div className="relative lg:-mt-24">
                <BottomWave/>
            </div>
        </>
    )
}
