import React from "react"
import Layout from "../components/layout"

import ProductHero from "../components/product/product-header";
import ProductStoreSynchronisation from "../components/product/product-store-synchronisation";
import ProductPlayout from "../components/product/product-playout";
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";

export default function Pricing() {
  return (
    <>
      <GatsbySeo title="Product - Subscriptions as a Service"
                 description={`LinkFive simplifies your subscription business from creating your subscriptions in all App Stores to implementing purchases in your app.`}
      />
      <Layout fullWave="false" bottomWave="false" callToAction="true" utmSource="product">
        <ProductHero/>
        <ProductStoreSynchronisation/>
        <ProductPlayout/>
      </Layout>
    </>
  )
}
