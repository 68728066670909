import React from "react"

import Header1 from "/src/components/header1"

import linkFiveCreateSub from "/src/assets/product/linkfive-create-subscription-and-synchronize-with-app-stores.png"

export default function ProductStoreSynchronisation(props) {
    return (
        <>
            <section className="bg-white py-8" id="product">
                <div className="container max-w-6xl mx-auto m-8">
                    <Header1
                        headerText="Create and Manage your App Store Subscriptions"
                        widthClass="w-4/6"
                    />
                    <div className="flex flex-wrap mt-20">
                        <div className="sm:w-1/2 p-6">
                            <h3 className="mt-2 mb-8 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                                Create your Subscriptions with LinkFive
                            </h3>
                            <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                                With LinkFive, you are able to create your subscriptions in one place and sync them with
                                your app stores like Google Play Store or Apple App Store Connect.
                            </p>
                            <p className="max-w-2xl mt-12 text-xl leading-7 text-gray-800"><b>Your benefit:</b></p>

                            <ul className="list-disc max-w-2xl mt-0 ml-6 text-xl leading-7 text-gray-800">
                                <li>Create once & synchronize automatically with all Stores</li>
                                <li>Instant subscription delivery to your App via SDK or API</li>
                                <li>Enhance your subscription price with our price algorithms & price strategies</li>
                                <li>No need to compare your country prices between all stores</li>
                            </ul>

                            <h4 className="max-w-2xl mt-12 text-xl leading-7 text-gray-800">
                                <b>Edit and Manage your Subscription Store Listing</b>
                            </h4>
                            <p className="max-w-2xl mt-2 text-xl leading-7 text-gray-800">
                                It is possible to edit your subscription description and store listing in all languages
                                on LinkFive and synchronize them with your App Stores.
                            </p>
                        </div>
                        <div className="w-full sm:w-1/2 p-12">
                            <img alt="LinkFive can create your subscription and synchronize them with all app stores"
                                 title="LinkFive Synchronize your subscriptions to all app stores"
                                 src={linkFiveCreateSub}/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
