import React from "react"

import Header1 from "/src/components/header1"

import linkFivePlayout
    from "/src/assets/product/linkfive-deliver-your-subscriptions-and-filter-by-country-or-many-more.png"
import linkFiveFilter from "/src/assets/product/linkfive-apply-your-subscription-filter.png"

export default function ProductPlayout(props) {
    return (
        <>
            <section className="bg-white py-8" id="product">
                <div className="container max-w-6xl mx-auto m-8 mt-12">
                    <Header1
                        headerText="Deliver your Subscriptions to your Users"
                        widthClass="w-4/6"
                    />
                    <div className="flex flex-wrap mt-20">
                        <div className="w-full sm:w-1/2 p-12">
                            <img alt="Playout our Subscriptions to your users"
                                 title="Playout our Subscriptions to your users"
                                 src={linkFivePlayout}/>
                        </div>
                        <div className="sm:w-1/2 p-6">
                            <h3 className="mt-2 mb-8 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                                LinkFive Subscription Playout
                            </h3>
                            <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                                Deliver your subscriptions directly to your users via API or SDK. Our service takes care
                                of the hard work so you can focus on your product.
                            </p>

                            <h4 className="max-w-2xl mt-12 text-xl leading-7 text-gray-800">
                                <b>Bundled Subscriptions</b>
                            </h4>
                            <p className="max-w-2xl mt-2 text-xl leading-7 text-gray-800">
                                Your subscriptions are in a single workspace and can be delivered bundled to all your
                                platforms simultaneously. No need to hardcode your product ID or SKU into your app anymore,
                                our SDK and API cover everything and provides your app with all the necessary
                                subscription information.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container max-w-6xl mx-auto m-8">
                    <div className="flex flex-wrap mt-10">
                        <div className="sm:w-1/2 p-6">
                            <h3 className="mt-2 mb-8 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                                Engage your target audience and deliver your subscriptions
                            </h3>
                            <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                                Distribute the right subscriptions to the right users by creating a filter. Our <b>Subscriptions
                                as a Service</b> system plays out the right subscriptions to your users.
                            </p>
                            <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                                Country targeting and marketing campaigns are easily possible with LinkFive!
                            </p>
                            <p className="max-w-2xl mt-12 text-xl leading-7 text-gray-800"><b>Filters to:</b></p>
                            <ul className="list-disc max-w-2xl mt-0 ml-6 text-xl leading-7 text-gray-800">
                                <li>Users in a specific country</li>
                                <li>Users with a specific app version</li>
                                <li>Specific users with your user ID</li>
                                <li>Users who came into the app with a UTM campaign</li>
                            </ul>
                            <p className="max-w-2xl mt-2 text-xl leading-7 text-gray-800">And many more...</p>
                        </div>

                        <div className="w-full sm:w-1/2 p-12">
                            <img alt="Playout our Subscriptions to your users"
                                 title="Playout our Subscriptions to your users"
                                 src={linkFiveFilter}/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
